import React from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function GoogleMap(){
  const defaultProps = {
    center: {
      lat: 133.057797,
      lng: -86.969325
    },
    zoom: 11
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '500px', width: '100%' }}>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3343.9494903567606!2d-86.97151408481079!3d33.0577965808881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x14b6709ab4be34a9!2zMzPCsDAzJzI4LjEiTiA4NsKwNTgnMDkuNiJX!5e0!3m2!1sen!2sus!4v1649802980391!5m2!1sen!2sus" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  );
}